import { Typography } from "@mui/material"
import React from "react"

const Font56 = ({ children, component, fw, tt, style, ...rest }) => {
  return (
    <Typography
      variant="h1"
      fontSize={"3.115rem"}
      fontWeight={fw || 900}
      style={{
        textTransform: tt || "initial",
        ...style,
      }}
      {...rest}
      gutterBottom
    >
      {children}
    </Typography>
  )
}

export default Font56
