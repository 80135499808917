import React from "react"
import MedicalServicesIcon from "@mui/icons-material/MedicalServices"
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety"
import ForumIcon from "@mui/icons-material/Forum"
import SchoolIcon from "@mui/icons-material/School"

export const benefits = [
  {
    name: "Pakiet medyczny",
    description:
      "Każdy z naszych pracowników ma możliwość korzystania z prywatnej opieki medycznej w ramach benefitów zespołowych.",
    icon: <MedicalServicesIcon />,
  },
  {
    name: "Ubezpieczenie grupowe",
    description:
      "Pracując w Medirze możesz skorzystać z prywatnego ubezpieczenia.",
    icon: <HealthAndSafetyIcon />,
  },
  {
    name: "Kurs języka niemieckiego",
    description:
      "Pracownicy zasilający szeregi naszej firmy mogą korzystać z wewnętrznego kursu językowego.",
    icon: <ForumIcon />,
  },
  {
    name: "Szkolenia",
    description:
      "Każdy z naszych pracowników może korzystać z firmowego budżetu szkoleniowego na rozwój swoich kompetencji z zakresu  swoich zadań. ",
    icon: <SchoolIcon />,
  },
]
