import React from "react"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font18 from "components/Fonts/Font18"
import { graphql, useStaticQuery } from "gatsby"
import { Grid } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Introduction = () => {
  const { intro } = useStaticQuery(
    graphql`
      query {
        intro: file(name: { eq: "career-introduction" }) {
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "#e5f5f7"
              quality: 90
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const image = getImage(intro)

  return (
    <section>
      <Hr mb={4} mt={6} />
      <Font42>Dołącz do nas</Font42>
      <Grid
        container
        mt={4}
        alignItems="center"
        spacing={4}
        rowSpacing={0}
        mb={10}
      >
        <Grid item xs={12} md={6}>
          <Font18 textAlign="justify" mb={4}>
            Od lat dokładamy wszelkich starań, aby nasz zespół tworzyli ludzie z
            pasją i pomysłami. Jesteśmy dynamicznie rozwijającą się firmą, która
            tworzy i działa z misją. W naszej pracy kierujemy się wartościami,
            które pozwalają nam realizować nasze zadania z jak największą
            skutecznością.
          </Font18>
          <Font18 textAlign="justify">
            Systematycznie poszukujemy nowych osób, które zasilą szeregi naszego
            teamu. Jeżeli poniżej nie znajdziesz aktualnie aktywnych rekrutacji
            nic nie stoi na przeszkodzie, abyś mimo wszystko wysłał do nas swoje
            CV. Serdecznie zapraszamy Cię do kontaktu, jeżeli tak jak nam zależy
            Ci na rozwoju i pracy, która czyni coś dobrego dla innych.
          </Font18>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent={"center"}>
          <GatsbyImage image={image} alt="Dołącz do nas" />
        </Grid>
      </Grid>
    </section>
  )
}

export default Introduction
