import { Grid, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import Font56 from "components/Fonts/Font56"
import Font18 from "components/Fonts/Font18"
import { GatsbyImage } from "gatsby-plugin-image"

const Header = ({
  title,
  titleImage,
  titleDescription,
  titleImageAlt,
  mainImage,
  mainImageAlt,
  titleDescriptionStyles,
  button,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Grid
      as="header"
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{
        margin: { xs: "0px 0 35px", md: "70px 0 35px" },
      }}
      flexDirection={{ xs: "column-reverse", md: "row" }}
    >
      <Grid item xs={12} md={6} sx={{ marginTop: isMobile ? "20px" : 0 }}>
        {titleImage && (
          <GatsbyImage image={titleImage} alt={titleImageAlt || ""} />
        )}
        {!!title && <Font56>{title}</Font56>}
        {titleDescription && (
          <Font18 textAlign="justify" style={titleDescriptionStyles || {}}>
            {titleDescription}
          </Font18>
        )}
        {button}
      </Grid>
      <Grid
        item
        minHeight={isMobile ? "auto" : 335}
        maxWidth={950}
        container
        alignItems="center"
        justifyContent={{ xs: "center", md: "end" }}
        xs={12}
        md={6}
      >
        <GatsbyImage image={mainImage} alt={mainImageAlt || ""} />
      </Grid>
    </Grid>
  )
}

export default Header
