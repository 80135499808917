import React from "react"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font24 from "components/Fonts/Font24"
import { graphql, useStaticQuery } from "gatsby"
import { Card, Grid } from "@mui/material"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Font18 from "components/Fonts/Font18"
import { companyData } from "constants/companyData"
import { options } from "constants/richTextOptions"

const JobOffers = () => {
  const data = useStaticQuery(graphql`
    query JobOffersQuery {
      allContentfulOfertaPracy {
        nodes {
          name
          description {
            raw
          }
          contentful_id
        }
      }
    }
  `)

  const offers = data.allContentfulOfertaPracy.nodes.filter(offer => offer.name !== "NIE USUWAĆ WPISU, KONIECZNY DO POPRAWNEGO DZIAŁANIA STRONY");

  return (
    <section>
      <Hr mb={4} mt={6} />
      <Font42 mb={6}>Aktualne oferty pracy</Font42>

      {Boolean(offers.length) ?
     ( <Grid
        container
        spacing={{ xs: 5, md: 8, lg: 15 }}
        mb={9}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        {offers.map(({ name, description, contentful_id }) => {
          return (
            <Grid key={contentful_id} item xs={12} md={6} maxWidth={600}>
              <Card>
                <Font24 mb={3}>{name}</Font24>
                <>{renderRichText(description, options)}</>
              </Card>
            </Grid>
          )
        })}
      </Grid>): null  
    }
    
      <Grid container justifyContent="center" alignItems="center" mt={1}>
        <Grid item xs={7}>
          <Font18 fw={700}>
            Jeśli nie znalazłeś nic dla siebie, nic straconego! Prześlij nam
            swoje CV na adres{" "}
            <a href={`mailto:${companyData.recrutationMail}`}>
              rekrutacja@medira.pl
            </a>
            , a my sprawdzimy czy znajdziemy dla Ciebie miejsce!
          </Font18>
        </Grid>
      </Grid>
    </section>
  )
}

export default JobOffers
