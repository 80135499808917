import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Introduction from "containers/Career/Introduction/Introduction"
import Benefits from "containers/Career/Benefits/Benefits"
import JobOffers from "containers/Career/JobOffers/JobOffers"

const Career = () => {
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    {
      title: "O nas",
      link: "/o-nas/firma",
    },
    "Kariera",
  ]
  const { career } = useStaticQuery(
    graphql`
      query {
        career: file(name: { eq: "career-main" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 90
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const mainImage = getImage(career)
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Kariera">
      <Header
        title={"Kariera"}
        mainImage={mainImage}
        mainImageAlt={"Kariera"}
      />
      <Introduction />
      <Benefits />
      <JobOffers />
    </Layout>
  )
}

export default Career
